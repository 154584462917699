import React from "react";
import "./HeaderAboveNav.scss";
import GatsbyLink from "../GatsbyLink";
import { FaPhoneAlt } from "react-icons/fa";

export const HeaderAboveNav = ({ aboveNavItems }) => {
  return (
    <div className="above-primary-navigation">
      <a href="tel:1800664787" className="aboveNavItem phone-button">
        <FaPhoneAlt />
        1800 MNG SURVEY
      </a>
      {Object.keys(aboveNavItems).length > 0 &&
        aboveNavItems?.map((navItem, index) => {
          return (
            <GatsbyLink
              to={navItem.url}
              target={navItem.target ? navItem.target : null}
              decode={true}
              key={index}
              className={`aboveNavItem ${navItem.classes}`}
            >
              {navItem.title}
            </GatsbyLink>
          );
        })}
    </div>
  );
};
