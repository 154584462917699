import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Logo } from "../Logo";
import GatsbyLink from "../GatsbyLink";
import { SocialLinks } from "../SocialLinks";
import "./Footer.scss";

const FooterComponent = ({ data, location }) => {
  const {
    legalNavigation,
    footerNavigation,
    siteSettings,
    wordpressWpSettings,
  } = data;
  const { items: footerNav } = footerNavigation;
  const { items: legalNav } = legalNavigation;

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer layout">
      <div className="footer__wrapper">
        <div>
          <Logo className="footer__logo" />
          {footerNav && (
            <ul className="footer_navigation">
              {Object.keys(footerNav).length > 0 &&
                footerNav?.map((item, index) => {
                  return (
                    <li key={index}>
                      <GatsbyLink to={item.url} decode={true}>
                        {item.title}
                      </GatsbyLink>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
        <SocialLinks />

        <div className="footer_legal">
          {legalNav && (
            <ul className="footer_legalnavigation">
              <li>ABN 90 009 363 311</li>
              {Object.keys(legalNav).length > 0 &&
                legalNav?.map((item, index) => {
                  return (
                    <li key={index}>
                      <GatsbyLink to={item.url} decode={true}>
                        {item.title}
                      </GatsbyLink>
                    </li>
                  );
                })}
              <li>
                © <span className="m-only">{wordpressWpSettings.title}</span>{" "}
                {currentYear}
              </li>
            </ul>
          )}
        </div>
      </div>
    </footer>
  );
};

export function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          legalNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "legal-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
            }
          }
          footerNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "footer-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
              # children: wordpress_children {
              #   title
              #   url
              #   classes
              #   slug: object_slug
              # }
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              locations {
                addressDisplay
                addressStreet
                addressCity
                addressRegion
                addressPostCode
                email
                phone
              }
              socialLinksFooter
            }
          }
          wordpressWpSettings {
            title
          }
        }
      `}
      render={(data) => <FooterComponent data={data} {...props} />}
    />
  );
}
