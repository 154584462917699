import React from "react";
import { NavItem } from "./NavItem";

import "./HeaderNavDesktop.scss";
import "./HeaderNavMobile.scss";

export const HeaderNav = ({ navItems, onArrowClick, openChild }) => {
  return (
    <ul className="header__navigation">
      {Object.keys(navItems).length > 0 &&
        navItems?.map((navItem, index) => {
          return (
            <NavItem
              key={index}
              item={navItem}
              index={index}
              onArrowClick={onArrowClick}
              openChild={openChild}
            />
          );
        })}
    </ul>
  );
};
